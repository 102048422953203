<template>
    <div>
        <div class="header">
            <span class="header_txt">活动样式</span>
        </div>
        <div class="header_box">
            <div class="left">
                <!-- <span class="header_span">样式分类：</span>
                <Select v-model="modelSelect" style="width:200px">
                    <Option v-for="item in liveList" :value="item.value" :key="item.value" :disabled="item.disabled">{{ item.label }}</Option>
                </Select> -->
            </div>
            <div class="right">
                <button class="header_btn" @click="addActivity">添加样式</button>
            </div>
        </div>
        <div class="content">
            <Table :columns="liveTable" :data="dataTable" border>
                <template slot-scope="{row}" slot="introcover">
                    <div style="display:flex;align-items:center;height: 56px;justify-content:center">
                        <img v-if="row.introcover" :src="row.introcover.path" alt="" style="width: 50px;">
                        <div v-else style="width: 56px;height: 56px;background-color: #f5f7fa;color:#c0c4cc;line-height:56px;text-align:center;">加载失败</div>
                    </div>
                </template>
                <template slot-scope="{row}" slot="theme_color">
                    <div :style="'width: 100%;height: 30px;border-radius:5px;background-color:' + row.theme_color"></div>
                </template>
                <template slot-scope="{row}" slot="is_use">
                    <span v-if="row.is_use == 0" style="color:#409eff;cursor: pointer;" @click="scene(row)">更换场景</span>
                    <span v-if="row.is_use == 1" style="color: #c0c4cc;cursor: not-allowed;">当前使用</span>
                </template>
                <template slot-scope="{row}" slot="type">
                    <span style="color:#409eff;cursor: pointer;" @click="edit(row)">编辑</span>
                </template>
            </Table>
        </div>
        <div class="footer">
            <Page :total="total" show-elevator show-total @on-change="pageChange"/>
        </div>
        <!-- 活动样式弹框 -->
        <Modal v-model="activityModal" width="50%">
            <div>
                <div class="activityModal_box">
                    <h2>品宣设置</h2>
                    <h4 class="activityModal_box_txt">样式标题</h4>
                    <Input v-model="activityStyle.title" type="text" style="width: 100%;" placeholder="请输入样式标题"></Input>
                    <h4 class="activityModal_box_txt">主题颜色</h4>
                    <div style="display:flex;flex-direction:row;align-items:center;justify-content:space-between">
                        <div style="display:flex;flex-direction:row;align-items:center;">
                            <span class="activityModal_box_txt" style="color:#808080;margin-right: 20px;">当前颜色</span>
                            <div :style="'width: 80px;height: 30px;border-radius:5px;background-color:' + activityStyle.theme_color"></div>
                        </div>
                        <div>
                            <span style="color:#808080;margin-right: 10px;">自定义颜色</span>
                            <ColorPicker v-model="activityStyle.theme_color" style="cursor: pointer;"/>
                        </div>
                    </div>
                    <div class="activityModal_color">
                        <div v-for="(item,index) in colorBtn" :key=index :style="'width: 20%;height: 30px;border-radius:5px;margin:10px;cursor: pointer;background-color:' + item.color" @click="changeColor(item)"></div>
                    </div>
                    <h4 class="activityModal_box_txt">背景颜色</h4>
                    <div style="display:flex;flex-direction:row;align-items:center;justify-content:space-between">
                        <div style="display:flex;flex-direction:row;align-items:center;">
                            <span class="activityModal_box_txt" style="color:#808080;margin-right: 20px;">当前颜色</span>
                            <div :style="'width: 80px;height: 30px;border-radius:5px;background-color:' + activityStyle.bg_color"></div>
                        </div>
                        <div>
                            <span style="color:#808080;margin-right: 10px;">自定义颜色</span>
                            <ColorPicker v-model="activityStyle.bg_color" style="cursor: pointer;"/>
                        </div>
                    </div>
                    <div class="activityModal_color">
                        <div v-for="(item,index) in bgColorBtn" :key=index :style="'width: 20%;height: 30px;border-radius:5px;margin:10px;cursor: pointer;background-color:' + item.color" @click="changeBgcolor(item)"></div>
                    </div>
                    <h4 class="activityModal_box_txt">分享颜色</h4>
                    <div style="display:flex;flex-direction:row;align-items:center;justify-content:space-between">
                        <div style="display:flex;flex-direction:row;align-items:center;">
                            <span class="activityModal_box_txt" style="color:#808080;margin-right: 20px;">当前颜色</span>
                            <div :style="'width: 80px;height: 30px;border-radius:5px;background-color:' + activityStyle.share_color"></div>
                        </div>
                        <div>
                            <span style="color:#808080;margin-right: 10px;">自定义颜色</span>
                            <ColorPicker v-model="activityStyle.share_color" style="cursor: pointer;"/>
                        </div>
                    </div>
                    <div class="activityModal_color">
                        <div v-for="(item,index) in shareColorBtn" :key=index :style="'width: 20%;height: 30px;border-radius:5px;margin:10px;cursor: pointer;background-color:' + item.color" @click="changeSharecolor(item)"></div>
                    </div>
                    <h4 class="activityModal_box_txt">活动顶部图</h4>
                    <Upload
                        ref="upload"
                        :show-upload-list="false"
                        :format="['jpg', 'jpeg', 'png', 'gif']"
                        :max-size="2048"
                        :on-format-error="handleFormatError"
                        :on-success="topSuccess"
                        :on-progress="handleProgress"
                        :headers="headers"
                        :action="uploadImg"
                        >
                        <div class="form_upload">
                            <div v-if="activityStyle.top_id" class="mask_upload" style="margin: 0;">
                                <img :src="activityStyle.tops.path" alt="" class="mask_img" />
                                <a href="#">
                                    <div class="mask">
                                        <Icon class="mask_icon" type="ios-trash-outline" @click.stop="topsDelete" />
                                    </div>
                                </a>
                            </div>
                            <div v-else><Icon class="form_icon" type="md-add" /></div>
                        </div>
                    </Upload>
                    <div style="display:flex;flex-direction:row;align-items:center;">
                        <h4 class="activityModal_box_txt" style="margin-right:10px;">企业介绍图</h4>
                        <!-- 开关 -->
                        <template>
                            <i-switch v-model="activityStyle.is_intro" :true-value="1" :false-value="0"></i-switch>
                        </template>
                    </div>
                    <div v-if="activityStyle.is_intro">
                        <span class="activityModal_box_txt" style="color:#808080;margin: 5px 0;">企业介绍封面图</span>
                        <Upload
                            ref="upload"
                            :show-upload-list="false"
                            :format="['jpg', 'jpeg', 'png', 'gif']"
                            :max-size="2048"
                            :on-format-error="handleFormatError"
                            :on-success="introcoverSuccess"
                            :on-progress="handleProgress"
                            :headers="headers"
                            :action="uploadImg"
                            >
                            <div class="form_upload">
                                <div v-if="activityStyle.intro_cover_id" class="mask_upload" style="margin: 0;">
                                    <img :src="activityStyle.introcover.path" alt="" class="mask_img" />
                                    <a href="#">
                                        <div class="mask">
                                            <Icon class="mask_icon" type="ios-trash-outline" @click.stop="introcoverDelete" />
                                        </div>
                                    </a>
                                </div>
                                <div v-else><Icon class="form_icon" type="md-add" /></div>
                            </div>
                        </Upload>
                        <span class="activityModal_box_txt" style="color:#808080;margin: 5px 0;">企业介绍图</span>
                        <div class="form_mask">
                            <div v-for="(item, index) of activityStyle.intros" class="mask_upload" :key="index">
                                <img :src="item.img.path" alt="" class="mask_img" />
                                <a href="#">
                                    <div class="mask">
                                        <Icon class="mask_icon" type="ios-trash-outline" @click="introsDelete(item,index)" />
                                    </div>
                                </a>
                            </div>
                            <Upload
                            ref="upload"
                            :show-upload-list="false"
                            :format="['jpg', 'jpeg', 'png', 'gif']"
                            :max-size="2048"
                            :on-format-error="handleFormatError"
                            :on-success="introSuccess"
                            :on-progress="handleProgress"
                            :headers="headers"
                            :action="uploadImg"
                            >
                                <div class="form_upload">
                                    <Icon class="form_icon" type="md-add" />
                                </div>
                            </Upload>
                        </div>
                    </div>
                    <h4 class="activityModal_box_txt">宣传视频</h4>
                    <h4>(视频最大128M)</h4>
                    <Upload
                        ref="upload"
                        :show-upload-list="false"
                        :format="['mp4', 'avi', 'mov']"
                        :max-size="128 * 1024"
                        :on-format-error="handleFormatError"
                        :on-success="videoSuccess"
                        :on-progress="handleProgress"
                        :headers="headers"
                        :action="uploadVideo"
                        >
                        <div class="form_upload">
                            <div v-if="activityStyle.intro_video_id" class="mask_upload" style="margin: 0;">
                                <video :src="activityStyle.introvideo.path" class="mask_img"></video>
                                <a href="#">
                                    <div class="mask">
                                        <Icon class="mask_icon" type="ios-trash-outline" @click.stop="introvideoDelete" />
                                    </div>
                                </a>
                            </div>
                            <div v-else><Icon class="form_icon" type="md-add" /></div>
                        </div>
                    </Upload>
                    <h4 class="activityModal_box_txt">用户分享图</h4>
                    <Upload
                        ref="upload"
                        :show-upload-list="false"
                        :format="['jpg', 'jpeg', 'png', 'gif']"
                        :max-size="2048"
                        :on-format-error="handleFormatError"
                        :on-success="shareSuccess"
                        :on-progress="handleProgress"
                        :headers="headers"
                        :action="uploadImg"
                        >
                        <div class="form_upload">
                            <div v-if="activityStyle.share_id" class="mask_upload" style="margin: 0;">
                                <img :src="activityStyle.share.path" alt="" class="mask_img" />
                                <a href="#">
                                    <div class="mask">
                                        <Icon class="mask_icon" type="ios-trash-outline" @click.stop="shareDelete" />
                                    </div>
                                </a>
                            </div>
                            <div v-else><Icon class="form_icon" type="md-add" /></div>
                        </div>
                    </Upload>
                </div>
                <div class="activityModal_box">
                    <h2>海报设置</h2>
                    <button class="header_btn" style="margin: 10px 0;" @click="addPoster">添加海报</button>
                    <div>
                        <Table :columns="modalTable" :data="modalDataTable" :no-data-text="noDataText">
                            <template slot-scope="{row}" slot="img">
                                <div style="display:flex;align-items:center;height: 56px;justify-content:center">
                                    <img v-if="row.img_id" :src="row.path" alt="" style="width: 50px;">
                                    <div v-else style="width: 56px;height: 56px;background-color: #f5f7fa;color:#c0c4cc;line-height:56px;text-align:center;">加载失败</div>
                                </div>
                            </template>
                            <template slot-scope="{row, index}" slot="type">
                                <Button type="primary" @click="editPoster(row, index)" style="margin-right:10px">编辑</Button>
                                <Button type="error" @click="delPoster(row, index)">删除</Button>
                            </template>
                        </Table>
                    </div>
                </div>
                <div class="activityModal_box">
                    <h2>活动说明</h2>
                    <h4 class="activityModal_box_txt">底部活动详情介绍图</h4>
                    <div class="form_mask">
                        <div v-for="(item, index) of activityStyle.bottoms" class="mask_upload" :key="index">
                            <img :src="item.img.path" alt="" class="mask_img"/>
                            <a href="#">
                                <div class="mask">
                                    <Icon class="mask_icon" type="ios-trash-outline" @click="bottomsDelete(item,index)"/>
                                </div>
                            </a>
                        </div>
                        <Upload
                        ref="upload"
                        :show-upload-list="false"
                        :format="['jpg', 'jpeg', 'png', 'gif']"
                        :max-size="2048"
                        :on-format-error="handleFormatError"
                        :on-success="bottomSuccess"
                        :on-progress="handleProgress"
                        :headers="headers"
                        :action="uploadImg"
                        >
                            <div class="form_upload">
                                <Icon class="form_icon" type="md-add" />
                            </div>
                        </Upload>
                    </div>
                    <h4 class="activityModal_box_txt">种草顶部说明图</h4>
                    <Upload
                        ref="upload"
                        :show-upload-list="false"
                        :format="['jpg', 'jpeg', 'png', 'gif']"
                        :max-size="2048"
                        :on-format-error="handleFormatError"
                        :on-success="recommendtopSuccess"
                        :on-progress="handleProgress"
                        :headers="headers"
                        :action="uploadImg"
                        >
                        <div class="form_upload">
                            <div v-if="activityStyle.recommend_top_id" class="mask_upload" style="margin: 0;">
                                <img :src="activityStyle.recommendtop.path" alt="" class="mask_img" />
                                <a href="#">
                                    <div class="mask">
                                        <Icon class="mask_icon" type="ios-trash-outline" @click.stop="recommendtopDelete" />
                                    </div>
                                </a>
                            </div>
                            <div v-else><Icon class="form_icon" type="md-add" /></div>
                        </div>
                    </Upload>
                    <h4 class="activityModal_box_txt">秒杀顶部说明图</h4>
                    <Upload
                        ref="upload"
                        :show-upload-list="false"
                        :format="['jpg', 'jpeg', 'png', 'gif']"
                        :max-size="2048"
                        :on-format-error="handleFormatError"
                        :on-success="seckilltopSuccess"
                        :on-progress="handleProgress"
                        :headers="headers"
                        :action="uploadImg"
                        >
                        <div class="form_upload">
                            <div v-if="activityStyle.sale_top_id" class="mask_upload" style="margin: 0;">
                                <img :src="activityStyle.saletop.path" alt="" class="mask_img" />
                                <a href="#">
                                    <div class="mask">
                                        <Icon class="mask_icon" type="ios-trash-outline" @click.stop="saletopDelete" />
                                    </div>
                                </a>
                            </div>
                            <div v-else><Icon class="form_icon" type="md-add" /></div>
                        </div>
                    </Upload>
                    <h4 class="activityModal_box_txt">拼团顶部说明图</h4>
                    <Upload
                        ref="upload"
                        :show-upload-list="false"
                        :format="['jpg', 'jpeg', 'png', 'gif']"
                        :max-size="2048"
                        :on-format-error="handleFormatError"
                        :on-success="teamtopSuccess"
                        :on-progress="handleProgress"
                        :headers="headers"
                        :action="uploadImg"
                        >
                        <div class="form_upload">
                            <div v-if="activityStyle.teamwork_top_id" class="mask_upload" style="margin: 0;">
                                <img :src="activityStyle.teamworktop.path" alt="" class="mask_img" />
                                <a href="#">
                                    <div class="mask">
                                        <Icon class="mask_icon" type="ios-trash-outline" @click.stop="teamworktopDelete" />
                                    </div>
                                </a>
                            </div>
                            <div v-else><Icon class="form_icon" type="md-add" /></div>
                        </div>
                    </Upload>
                    <h4 class="activityModal_box_txt">定金顶部说明图</h4>
                    <Upload
                        ref="upload"
                        :show-upload-list="false"
                        :format="['jpg', 'jpeg', 'png', 'gif']"
                        :max-size="2048"
                        :on-format-error="handleFormatError"
                        :on-success="depositSuccess"
                        :on-progress="handleProgress"
                        :headers="headers"
                        :action="uploadImg"
                        >
                        <div class="form_upload">
                            <div v-if="activityStyle.deposit_top_id" class="mask_upload" style="margin: 0;">
                                <img :src="activityStyle.deposittop.path" alt="" class="mask_img" />
                                <a href="#">
                                    <div class="mask">
                                        <Icon class="mask_icon" type="ios-trash-outline" @click.stop="deposittopDelete" />
                                    </div>
                                </a>
                            </div>
                            <div v-else><Icon class="form_icon" type="md-add" /></div>
                        </div>
                    </Upload>
                    <h4 class="activityModal_box_txt">预约顶部说明图</h4>
                    <Upload
                        ref="upload"
                        :show-upload-list="false"
                        :format="['jpg', 'jpeg', 'png', 'gif']"
                        :max-size="2048"
                        :on-format-error="handleFormatError"
                        :on-success="ordertopSuccess"
                        :on-progress="handleProgress"
                        :headers="headers"
                        :action="uploadImg"
                        >
                        <div class="form_upload">
                            <div v-if="activityStyle.book_top_id" class="mask_upload" style="margin: 0;">
                                <img :src="activityStyle.booktop.path" alt="" class="mask_img" />
                                <a href="#">
                                    <div class="mask">
                                        <Icon class="mask_icon" type="ios-trash-outline" @click.stop="booktopDelete" />
                                    </div>
                                </a>
                            </div>
                            <div v-else><Icon class="form_icon" type="md-add" /></div>
                        </div>
                    </Upload>
                </div>
            </div>
            <div slot="footer">
                <Button @click="activityModalF">取消</Button>
                <Button type="primary" @click="activityModalT">确定</Button>
            </div>
            <div slot="close"></div>
        </Modal>
        <!-- 添加海报弹框 -->
        <Modal v-model="posterModal" width="40%">
            <div>
                <div class="activityModal_box">
                    <h4 class="activityModal_box_txt">海报图片</h4>
                    <Upload
                        ref="upload"
                        :show-upload-list="false"
                        :format="['jpg', 'jpeg', 'png', 'gif']"
                        :max-size="2048"
                        :on-format-error="handleFormatError"
                        :on-success="bannerSuccess"
                        :on-progress="handleProgress"
                        :headers="headers"
                        :action="uploadImg"
                        >
                        <div class="form_upload">
                            <img v-if="banner_obj.img_id" style="width: 150px;height: 150px;border-radius: 10px;" :src="banner_obj.path" alt="">
                            <Icon v-else class="form_icon" type="md-add" />
                        </div>
                    </Upload>
                    <h4 class="activityModal_box_txt">背景颜色</h4>
                    <div style="display:flex;flex-direction:row;align-items:center;justify-content:space-between">
                        <span>当前颜色</span>
                        <div>
                            <span style="margin-right: 10px;color:#808080;">修改颜色</span>
                            <ColorPicker v-model="banner_obj.background" style="cursor: pointer;"/>
                        </div>
                    </div>
                    <h4 class="activityModal_box_txt">分享人字体颜色</h4>
                    <div style="display:flex;flex-direction:row;align-items:center;justify-content:space-between">
                        <span>当前颜色</span>
                        <div>
                            <span style="margin-right: 10px;color:#808080;">修改颜色</span>
                            <ColorPicker v-model="banner_obj.manFontColor" style="cursor: pointer;"/>
                        </div>
                    </div>
                    <h4 class="activityModal_box_txt">分享语字体颜色</h4>
                    <div style="display:flex;flex-direction:row;align-items:center;justify-content:space-between">
                        <span>当前颜色</span>
                        <div>
                            <span style="margin-right: 10px;color:#808080;">修改颜色</span>
                            <ColorPicker v-model="banner_obj.sayFontColor" style="cursor: pointer;"/>
                        </div>
                    </div>
                    <h4 class="activityModal_box_txt">分享语主标题</h4>
                    <Input v-model="banner_obj.title_one" type="text" style="width: 100%;" placeholder="请输入分享语主标题"></Input>
                    <h4 class="activityModal_box_txt">分享语副标题</h4>
                    <Input v-model="banner_obj.title_two" type="text" style="width: 100%;" placeholder="请输入分享语副标题"></Input>
                    <h4 class="activityModal_box_txt">分享语背景颜色</h4>
                    <div style="display:flex;flex-direction:row;align-items:center;justify-content:space-between">
                        <span>当前颜色</span>
                        <div>
                            <span style="margin-right: 10px;color:#808080;">修改颜色</span>
                            <ColorPicker v-model="banner_obj.sayBackground" style="cursor: pointer;"/>
                        </div>
                    </div>
                    <h4 class="activityModal_box_txt">分享语边框颜色</h4>
                    <div style="display:flex;flex-direction:row;align-items:center;justify-content:space-between">
                        <span>当前颜色</span>
                        <div>
                            <span style="margin-right: 10px;color:#808080;">修改颜色</span>
                            <ColorPicker v-model="banner_obj.frame" style="cursor: pointer;"/>
                        </div>
                    </div>
                </div> 
            </div>
            <div slot="footer">
                <Button @click="posterModalF">取消</Button>
                <Button type="primary" @click="posterModalT">确定</Button>
            </div>
            <div slot="close"></div>
        </Modal>
    </div>
</template>

<script>
import config from "@/config/index";
import { getCookies } from "@/utils/helper";
import {themeList, themeUseTheme, themeCreateTheme} from '@/api/index'
    export default {
        data() {
            return {
                uploadImg: config.apiUrl + "/admin/other/uploads",
                uploadVideo: config.apiUrl + "/admin/other/uploads_video",
                headers: {"meiye-admin-api": getCookies("meiye-admin-api")},
                // liveList: [
                //     {value: '0',label: '自定义样式',disabled:false},
                //     {value: '1',label: '官网推荐',disabled:true},
                // ],
                modelSelect:'0',
                //table
                liveTable: [
                    {title: '样式名称',key: 'title',align:'center'},
                    {title: '封面图',slot: 'introcover',align:'center'},
                    {title: '创建时间',key: 'created_at',align:'center'},
                    {title: '主题颜色',slot: 'theme_color',align:'center',width:120},
                    {title: '场景选用',slot: 'is_use',align:'center'},
                    {title: '操作',slot: 'type',align:'center'}
                ],
                dataTable: [],
                modalTable: [
                    {title: '图片',slot: 'img',align:'center'},
                    {title: '操作',slot: 'type',align:'center'}
                ],
                modalDataTable: [],
                //page
                total: 1,
                storePrams: {
                    page: 1,
                    limit: 30,
                },
                activityModal:false,
                // 活动样式
                activityStyle:{
                    title:'',
                    theme_color:''
                },
                colorBtn:[
                    {color:'rgb(170, 0, 1)'},
                    {color:'rgb(203, 137, 46)'},
                    {color:'rgb(212, 178, 55)'},
                    {color:'rgb(48, 239, 75)'},
                    {color:'rgb(58, 231, 214)'},
                    {color:'rgb(63, 126, 228)'},
                    {color:'rgb(181, 58, 188)'},
                    {color:'rgb(200, 51, 224)'},
                    {color:'rgb(97, 236, 146)'},
                    {color:'rgb(202, 58, 130)'},
                    {color:'rgb(156, 195, 57)'},
                    {color:'rgb(85, 100, 213)'}
                ],
                bgColorBtn:[
                    {color:'rgb(170, 0, 1)'},
                    {color:'rgb(203, 137, 46)'},
                    {color:'rgb(212, 178, 55)'},
                    {color:'rgb(48, 239, 75)'},
                    {color:'rgb(58, 231, 214)'},
                    {color:'rgb(63, 126, 228)'},
                    {color:'rgb(181, 58, 188)'},
                    {color:'rgb(200, 51, 224)'},
                    {color:'rgb(97, 236, 146)'},
                    {color:'rgb(202, 58, 130)'},
                    {color:'rgb(156, 195, 57)'},
                    {color:'rgb(85, 100, 213)'}
                ],
                shareColorBtn:[
                    {color:'rgb(170, 0, 1)'},
                    {color:'rgb(203, 137, 46)'},
                    {color:'rgb(212, 178, 55)'},
                    {color:'rgb(48, 239, 75)'},
                    {color:'rgb(58, 231, 214)'},
                    {color:'rgb(63, 126, 228)'},
                    {color:'rgb(181, 58, 188)'},
                    {color:'rgb(200, 51, 224)'},
                    {color:'rgb(97, 236, 146)'},
                    {color:'rgb(202, 58, 130)'},
                    {color:'rgb(156, 195, 57)'},
                    {color:'rgb(85, 100, 213)'}
                ],
                noDataText:'暂无数据',
                posterModal:false,
                banner_obj:{
                    path:'', img_id:'', background:'', manFontColor:'', sayFontColor:'', title_one:'', title_two:'', sayBackground:'', frame:''
                },
                switchValue:true,//开关
                posterValue: 0,
                posterActive: '' //海报设置index
            }
        },
        mounted(){
            this.themeList()
        },
        methods: {
            changeColor(item){
                this.activityStyle.theme_color = item.color
            },
            changeBgcolor(item){
                this.activityStyle.bg_color = item.color
            },
            changeSharecolor(item){
                this.activityStyle.share_color = item.color
            },
            // 删除图片
            introsDelete(item,i){
                this.activityStyle.intros.splice(i,1)
            },
            shareDelete(){
                this.activityStyle.share = {}
                this.activityStyle.share_id = ''
            },
            introvideoDelete(){
                this.activityStyle.introvideo = {}
                this.activityStyle.intro_video_id = ''
            },
            topsDelete(){
                this.activityStyle.tops = {}
                this.activityStyle.top_id = ''
            },
            introcoverDelete(){
                this.activityStyle.introcover = {}
                this.activityStyle.intro_cover_id = ''
            },
            recommendtopDelete(){
                this.activityStyle.recommendtop = {}
                this.activityStyle.recommend_top_id = ''
            },
            saletopDelete(){
                this.activityStyle.saletop = {}
                this.activityStyle.sale_top_id = ''
            },
            teamworktopDelete(){
                this.activityStyle.teamworktop = {}
                this.activityStyle.teamwork_top_id = ''
            },
            deposittopDelete(){
                this.activityStyle.deposittop = {}
                this.activityStyle.deposit_top_id = ''
            },
            booktopDelete(){
                this.activityStyle.booktop = {}
                this.activityStyle.book_top_id = ''
            },
            bottomsDelete(item,i){
                this.activityStyle.bottoms.splice(i,1)
            },
            // 场景选用
            scene(item){
                // 切换活动样式
                themeUseTheme({id:item.id}).then(res => {
                    this.themeList()
                    this.$Message.success(res.msg);
                })
                .catch((res) => {
                    this.$Message.error(res.msg);
                });
            },
            // 活动样式列表
            themeList(){
                themeList(this.storePrams).then(res => {
                    console.log('活动样式列表',res.data.data)
                    this.total = res.data.total
                    this.dataTable = res.data.data
                }) 
            },
            // 活动样式添加/编辑
            addActivity(){
                this.activityModal = true  
                this.activityStyle = { 
                    banners:[],
                    top_id: '',
                    tops: {},
                    title: '',
                    theme_color: this.colorBtn[0].color,
                    bg_color: this.bgColorBtn[0].color,
                    share_color: this.shareColorBtn[0].color,
                    is_intro: 1,
                    intro_cover_id: '',
                    introcover:{},
                    intro_video_id: '',
                    introvideo:{},
                    share_id: '',
                    share:{},
                    recommend_top_id: '',
                    recommendtop:{},
                    sale_top_id: '',
                    saletop:{},
                    teamwork_top_id: '',
                    teamworktop:{},
                    deposit_top_id: '',
                    deposittop:{},
                    book_top_id: '',
                    booktop:{},
                    bottom_id: '',
                    bottoms:[],
                    intros_id: '',
                    intros:[]
                }  
                this.modalDataTable = []
                this.activityStyle.id = ''
            },
            edit(item){
                console.log('大编辑', item)
                this.activityModal = true                
                this.modalDataTable = item.banners
                this.activityStyle = item
                if(!this.activityStyle.intro_video_id){
                    this.activityStyle.introvideo = {}
                }
                if(!this.activityStyle.top_id){
                    this.activityStyle.tops = {}
                }
                if(!this.activityStyle.intro_cover_id){
                    this.activityStyle.introcover = {}
                }
                if(!this.activityStyle.share_id){
                    this.activityStyle.share = {}
                }
                if(!this.activityStyle.recommend_top_id){
                    this.activityStyle.recommendtop = {}
                }
                if(!this.activityStyle.sale_top_id){
                    this.activityStyle.saletop = {}
                }
                if(!this.activityStyle.teamwork_top_id){
                    this.activityStyle.teamworktop = {}
                }
                if(!this.activityStyle.deposit_top_id){
                    this.activityStyle.deposittop = {}
                }
                if(!this.activityStyle.book_top_id){
                    this.activityStyle.booktop = {}
                }
                this.activityStyle.id = item.id
            },
            pageChange(index) {
                this.storePrams.page = index;
            },
            activityModalF(){
                this.activityModal = false
            },
            activityModalT(){
                var intro_ids =[]
                this.activityStyle.intros.forEach((item) => {
                    intro_ids.push(item.img_id)
                })
                this.activityStyle.intros_id = intro_ids.join(",")
                var bottoms_ids =[]
                this.activityStyle.bottoms.forEach((item) => {
                    bottoms_ids.push(item.img_id)
                })
                this.activityStyle.bottom_id = bottoms_ids.join(",")
                // 创建活动样式
                let data = {
                    banners: this.modalDataTable,
                    title: this.activityStyle.title,
                    theme_color: this.activityStyle.theme_color,
                    bg_color: this.activityStyle.bg_color,
                    share_color: this.activityStyle.share_color,
                    is_intro: this.activityStyle.is_intro,
                    intro_cover_id: this.activityStyle.intro_cover_id,
                    intro_video_id: this.activityStyle.intro_video_id,
                    share_id: this.activityStyle.share_id,
                    recommend_top_id: this.activityStyle.recommend_top_id,
                    sale_top_id: this.activityStyle.sale_top_id,
                    teamwork_top_id: this.activityStyle.teamwork_top_id,
                    deposit_top_id: this.activityStyle.deposit_top_id,
                    book_top_id: this.activityStyle.book_top_id,
                    // banners[0][img_id]: this.activityStyle.banners[0][img_id],
                    bottom_id: this.activityStyle.bottom_id,
                    intros_id: this.activityStyle.intros_id,
                    top_id: this.activityStyle.top_id,
                    id: this.activityStyle.id
                    // banners[1][img_id]: this.activityStyle.banners[1][img_id],
                    // banners[0][title]: this.activityStyle.banners[0][title],
                    // banners[1][title]: this.activityStyle.banners[1][title]
                }
                themeCreateTheme(data).then(res => {
                    this.activityModal = false
                    this.themeList()
                    this.$Message.success(res.msg);
                })
                .catch((res) => {
                    this.$Message.error(res.msg);
                });
            },
            addPoster(){
                this.banner_obj = {
                    path:'', img_id:'', background:'', manFontColor:'', sayFontColor:'', title_one:'', title_two:'', sayBackground:'', frame:''
                }
                this.posterModal = true
                this.posterValue = 0
            },
            editPoster(item, i){
                this.banner_obj = item
                this.posterModal = true
                this.posterValue = 1
                this.posterActive = i
            },
            delPoster(item, i){
                this.modalDataTable.splice(i, 1)
            },
            posterModalF(){
                this.posterModal = false
            },
            posterModalT(){
                this.posterModal = false
                if(this.posterValue == 0){
                    this.modalDataTable.push(this.banner_obj)
                }else{
                    this.modalDataTable[this.posterActive] = this.banner_obj
                }
            },
            //文件上传类型错误
            handleFormatError() {
                this.$Message.warning("暂不支持上传此格式");
            },
            // 文件上传时
            handleProgress(event, file, fileList) {
                if(event.percent == 100){
                    const msg = this.$Message.loading({
                        content: "上传中...",
                        duration: 0,
                    });
                    setTimeout(msg, 3000);
                }
            },
            // 活动顶部图
            topSuccess(e){
                console.log('eee',e)
                this.activityStyle.tops.path = e.data.path
                this.activityStyle.top_id = e.data.id
                this.$Message.destroy();
                this.$Message.success(e.msg);
            },
            // 企业介绍封面图
            introcoverSuccess(e){
                console.log('eee',e)
                this.activityStyle.introcover.path = e.data.path
                this.activityStyle.intro_cover_id = e.data.id
                this.$Message.destroy();
                this.$Message.success(e.msg);
            }, 
            // 企业介绍图
            introSuccess(e){
                console.log('eee',e)
                this.activityStyle.intros.push({'img_id' : e.data.id, 'img':{'path':e.data.path}});
                this.$Message.destroy();
                this.$Message.success(e.msg);
            },
            // 宣传视频
            videoSuccess(e){
                console.log('eee',e)
                this.activityStyle.introvideo.path = e.data.path
                this.activityStyle.intro_video_id = e.data.id
                this.$Message.destroy();
                this.$Message.success(e.msg);
            },
            // 用户分享图
            shareSuccess(e){
                console.log('eee',e)
                this.activityStyle.share.path = e.data.path
                this.activityStyle.share_id = e.data.id
                this.$Message.destroy();
                this.$Message.success(e.msg);
            },
            // 底部活动详情介绍图
            bottomSuccess(e){
                console.log('eee',e)
                this.activityStyle.bottoms.push({'img_id' : e.data.id, 'img':{'path':e.data.path}});
                this.$Message.destroy();
                this.$Message.success(e.msg);
            },
            // 种草顶部说明图
            recommendtopSuccess(e){
                console.log('eee',e)
                this.activityStyle.recommendtop.path = e.data.path
                this.activityStyle.recommend_top_id = e.data.id
                this.$Message.destroy();
                this.$Message.success(e.msg);
            },
            // 秒杀顶部说明图
            seckilltopSuccess(e){
                console.log('eee',e)
                this.activityStyle.saletop.path = e.data.path
                this.activityStyle.sale_top_id = e.data.id
                this.$Message.destroy();
                this.$Message.success(e.msg);
            },
            // 拼团顶部说明图
            teamtopSuccess(e){
                console.log('eee',e)
                this.activityStyle.teamworktop.path = e.data.path
                this.activityStyle.teamwork_top_id = e.data.id
                this.$Message.destroy();
                this.$Message.success(e.msg);
            },
            // 定金顶部说明图
            depositSuccess(e){
                console.log('eee',e)
                this.activityStyle.deposittop.path = e.data.path
                this.activityStyle.deposit_top_id = e.data.id
                this.$Message.destroy();
                this.$Message.success(e.msg);
            },
            // 预约顶部说明图
            ordertopSuccess(e){
                console.log('eee',e)
                this.activityStyle.booktop.path = e.data.path
                this.activityStyle.book_top_id = e.data.id
                this.$Message.destroy();
                this.$Message.success(e.msg);
            },
            // 海报图上传
            bannerSuccess(e) {
                console.log('eee',e)
                this.banner_obj.path = e.data.path
                this.banner_obj.img_id = e.data.id
                this.$Message.destroy();
                this.$Message.success(e.msg);
            },
        },
    }
</script>

<style lang="scss" scoped>
.form_mask .form_upload:hover{
    border: 1px dashed #409eff;
}
.form_mask{
    display:flex;
    flex-wrap:wrap;
    width: 100%;
}
.mask_upload{
    width: 150px;
    height: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border-radius: 10px;
    margin: 0 10px 10px 0;
    position: relative;
}
.mask_img{
    width: 150px;
    height:150px;
    border-radius: 10px;
}
.mask {
    position: absolute;
    top: 0;
    left: 0;
    width: 150px;
    height: 150px;
    background: rgba(101, 101, 101, 0.6);
    color: #ffffff;
    opacity: 0;
    border-radius: 10px;
}
.mask .mask_icon {
    font-size: 20px;
}
.mask_upload a:hover .mask {
    opacity: 1; 
    text-align: center;  
    height: 150px;
    line-height: 150px;        
}
.header{
    width: 100%;
    height: 55px;
    padding: 16px;
    background-color: #cee0f9;
    border-top-left-radius: 15px; 
    border-top-right-radius: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.header_txt{
    font-size: 20px;
    font-weight: bold;
    color: #000;
}
.header_btn{
    width: 100px;
    padding: 10px;
    background-color: #409eff;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}
.header_box{
    margin: 10px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 20px 40px 20px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    border-radius: 10px;
}
.left{
    display: flex;
    align-items: center;
}
.content{
    margin: 20px 0;
}
.footer{
    text-align: center;
}
.header_span{
    font-size: 16px;
    font-weight: 700;
    margin: 0 10px 0 10px;
}
.activityModal_box{
    padding: 10px 10px 20px 10px;
    margin: 10px;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.activityModal_box_txt{
    color: #5c80b1;
    margin: 10px 0;
}
.activityModal_color{
    display: flex;
    justify-content: space-around;
    flex-direction: row;
    flex-wrap: wrap;
}
.form_upload{
    width: 150px;
    height: 150px;
    border: 1px dashed #c0c0c0;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border-radius: 10px;
}
.form_upload:hover{
    border: 1px dashed #409eff;
}
.form_icon{
    font-size: 30px;
    color: #808080;
}
</style>